import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import FormEditor from "../../components/Base/FormEditor";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import { fetchApiData, saveApiData } from "../../request/config/index";
import {
  GET_TERMS_OF_SERVICES,
  SAVE_TERMS_OF_SERVICES,
} from "../../request/config/Urls";

const TermsOfServiceComponent = () => {
  const [termsAndServiesData, setTermsAndServiesData] = useState("");
 

  const dispatch = useDispatch();

  const onSubmit = async () => {
    try {
      let res = await saveApiData({
        url: SAVE_TERMS_OF_SERVICES,
        body: { terms_of_service: termsAndServiesData },
      });
      console.log("Terms Data: " + res);
      if (res) {
        dispatch(alertSuccess("Updated Successfully"));
        fetchData();
      }
    } catch (error: any) {
      dispatch(alertFailure("error:" + error));
    }
  };
  const fetchData = async () => {
    try {
      const response = await fetchApiData({ url: GET_TERMS_OF_SERVICES });
      setTermsAndServiesData(
        response.data[0] ? response.data[0]?.terms_of_service : ""
      );
    } catch (error: any) {
      console.error(
        `${error?.response?.data?.message || "Error fetching data:"}`
      );
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error fetching data:"}`
        )
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody className="p-4">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="card-title mb-1">Enter Terms of Service</h4>
                </div>
                <FormEditor
                  data={termsAndServiesData}
                  setData={setTermsAndServiesData}
                />
              </div>
              <div>
                <button
                  type="submit"
                  onClick={() => onSubmit()}
                  className="btn btn-info w-md mt-3"
                >
                  Submit
                </button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TermsOfServiceComponent;
