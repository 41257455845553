import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Pagination from "@material-ui/lab/Pagination";
import useStyles from "./style";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import SearchForm from "./SearchForm";

import { MenuItem, Select } from "@material-ui/core";
import SetBonusForm from "./SetBonusForm";
import Grid from "@material-ui/core/Grid";
import { UseCheckPermissions } from "../../utils/useCheckPermissions";
import NoPermisisons from "../../components/Base/NoPermissions";
import EmptyTable from "../../components/Base/Emptytable";
import DelegateRecord from "./UserRow";
import { getDelegateList } from "../../request/delegate";

const tableHeaders = [
  "Source Wallet Address",
  "Target Wallet Address",
  "Points Delegated",
  "TRANSACTION TYPE",
  "REVOKED",
  "UPDATED AT",
  "DELETED",
];

const DelegateList: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [selectedType, setSelectedType] = useState('all');
  const [lastPage, setLastPage] = useState(1);

  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [isOpenBonusModal, setIsOpenBonusModal] = useState(false);

  const getUserListInfo = async (query: any) => {
    let queryParams: any = {
      wallet_address: query,
      page: currentPage,
      limit: 10,
    };
    if (selectedType && selectedType !== "" && query !== "") {
      queryParams.transaction_type = selectedType;
    }
    if (selectedType == "all") {
      queryParams = {
        wallet_address: query,
        page: currentPage,
        limit: 10,
      };
    }

    try {
      setLoading(true);
      const resObject = await getDelegateList(queryParams);
      if (resObject.status === 200) {
        setUsers(resObject?.data?.data);
        setLastPage(resObject.data?.lastPage);
        setFailure(false);
      } else {
        setFailure(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFailure(true);
    }
  };

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleSelectedTierChange = (event: any) => {
    setCurrentPage(1);
    setSelectedType(event.target.value);
  };

  const handleSearch = (event: any) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getUserListInfo(query);
    }, 500);
    return () => clearTimeout(timer);
  }, [query, currentPage, selectedType]);

  const DELEGATE_LABELS = ["all", "sent", "received", "revoked","deleted"];


  // @ts-ignore
  const containsTransactionKey = users.some((item) => item.transaction_type);
  const filteredHeaders = tableHeaders.filter((header) => {
    if (header === "TRANSACTION TYPE") {
      return containsTransactionKey;
    }
    return true;
  });
  const isViewUserList = UseCheckPermissions("VIEW_USER_LIST");
  return (
    <DefaultLayout>
      {isViewUserList ? (
        <>
          <>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                maxWidth: "100%",
                marginTop: "15px",
                marginLeft: "12px",
              }}
            >
              {query && <Select
                name="transaction_type"
                value={selectedType}
                onChange={handleSelectedTierChange}
              >
                <MenuItem value="" selected disabled>
                  Select Type
                </MenuItem>
                {DELEGATE_LABELS.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>}

              <SearchForm seachValue={query} handleSearch={handleSearch} />
            </Grid>

            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              {loading ? (
                [...Array(10)].map((num, index) => (
                  <div key={index}>
                    <Skeleton className={classes.skeleton} width={"100%"} />
                  </div>
                ))
              ) : (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {filteredHeaders.map(
                        (tableHeader: string, index: number) => {
                          return (
                            <TableCell
                              key={index}
                              className={classes.tableHeader}
                            >
                              {tableHeader}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {users && users.length > 0 ? (
                      users.map((user: any, index: number) => (
                        <DelegateRecord
                          containsTransactionKey={containsTransactionKey}
                          key={user.id}
                          user={user}
                          getUserListInfo={getUserListInfo}
                        />
                      ))
                    ) : (
                      <EmptyTable title="Delegate" />
                    )}
                  </TableBody>
                </Table>
              )}
              {failure ? (
                <p className={classes.errorMessage}>{failure}</p>
              ) : (!users || users.length === 0) && !loading ? (
                <p className={classes.noDataMessage}>There is no data</p>
              ) : (
                <>
                  {users && users.length > 0 && lastPage > 1 && (
                    <Pagination
                      page={currentPage}
                      className={classes.pagination}
                      count={lastPage}
                      onChange={handlePaginationChange}
                    />
                  )}
                </>
              )}
            </TableContainer>

            <SetBonusForm
              isOpen={isOpenBonusModal}
              setIsOpen={setIsOpenBonusModal}
              getUserListInfo={getUserListInfo}
              setQuery={setQuery}
            />
          </>
        </>
      ) : (
        <NoPermisisons />
      )}
    </DefaultLayout>
  );
};

export default DelegateList;
