import React from "react";
import { TableCell, TableRow, Tooltip, Button } from "@material-ui/core";
import useComponentVisible from "../../../hooks/useComponentVisible";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import moment from "moment";

import useStyles from "./style";

type UserProps = {
  id: string;
  staking_pool_id: string;
  title: string;
  pool_type: string;
  is_locked: string;
  wallet_address: string;
  lock_duration: any;
  staked_amount: any;
  staked_point?: string;
  bonus_point?: string;
  total_point?: string;
  updated_at?: number | null;
};

type UserRowProps = {
  user: UserProps;
  getUserListInfo: any;
};

const StakingUserListRecord1: React.FC<UserRowProps> = (
  props: UserRowProps
) => {
  const { user } = props;
  const classes = useStyles();
  const { ref } = useComponentVisible();

  const maskWalletAddress = (wallet: string) => {
    const preWalletLength = wallet.length;

    let r = wallet.slice(5, preWalletLength - 5);
    wallet = wallet.replace(r, "*****");

    return wallet;
  };

  return (
    <>
      <TableRow ref={ref} className={classes.tableRow} key={user.id}>
        <TableCell className={classes.tableCell} align="left">
          <Tooltip
            title={<p style={{ fontSize: 15 }}>{user.wallet_address}</p>}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>{maskWalletAddress(user.wallet_address)}</span>
              <CopyToClipboard
                onCopy={() => NotificationManager.success("Copied")}
                text={user.wallet_address}
              >
                <Button style={{ minWidth: "20px" }}>
                  <img
                    src="/images/icon-copy.svg"
                    alt=""
                    style={{ maxHeight: "18px" }}
                  />
                </Button>
              </CopyToClipboard>
            </div>
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          <strong>{user.title}</strong>
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          <strong>{user.pool_type}</strong>
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          <strong>{Math.floor(user.lock_duration / 86400)} days</strong>
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          <strong>{Number(user.staked_amount).toFixed(2)}</strong>
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {user.is_locked === "1" ? (
            <img src="./images/tick.svg" alt="" />
          ) : (
            "No"
          )}
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          {user.updated_at
            ? moment(user.updated_at, "YYYY-MM-DD HH:mm:ss").format(
                "HH:mm DD/MM"
              )
            : "null"}
        </TableCell>
      </TableRow>
    </>
  );
};

export default StakingUserListRecord1;
