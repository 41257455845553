import { apiRoute } from "../utils";
import { BaseRequest } from "./Request";
const queryString = require("query-string");


export const deleteDelegatePoints = async (id: any) => {
    console.log(id, "id");
    const baseRequest = new BaseRequest();
    let url = apiRoute(`/delete-admin`);
    const response = (await baseRequest.delete(url, { id: [id] })) as any;
    const resObject = await response.json();
  
    return resObject;
  };

  export const getDelegateList = async (queryParams: any) => {
    const baseRequest = new BaseRequest();
    let url = apiRoute(`/delegate-staking-points`);
    url += "?" + queryString.stringify(queryParams);
  
    const response = (await baseRequest.get(url)) as any;
    const resObject = await response.json();
  
    return resObject;
  };

  
  