import React, { ReactNode, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { CircularProgress, Hidden } from "@material-ui/core";

import Transition from "../../../components/Base/Transition";
import useStyles from "./style";

type ConfirmDialogType = {
  title: string;
  children: JSX.Element | ReactNode;
  open: boolean;
  confirmLoading: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  btnLoading?: boolean;
  label?: string;
};

const TermLogsModal: React.FC<ConfirmDialogType> = (
  props: ConfirmDialogType
) => {
  const {
    title,
    children,
    open,
    onConfirm,
    onCancel,
    confirmLoading,
    btnLoading = false,
    label,
  } = props;
  const styles = useStyles();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
      className={styles.dialog}
    >
      <DialogContent
        className={styles.dialogContent}
        style={{
          paddingTop: "0px",
        }}
      >
        <div className={styles.dialogActions}>
          <div className={styles.dailogHeader}>
            <div className={styles.dailogHeaderWrapper}>
              <h1 id="form-dialog-title" className={styles.dailogTitle}>
                {title}
              </h1>
              <Button
                disabled={confirmLoading}
                className={`${styles.dialogButton} ${styles.dialogButtonCancel}`}
                onClick={onCancel}
                color="primary"
              >
                <img
                  src="/images/close_icon_b.svg"
                  alt=""
                  width={20}
                  style={{
                    filter: "invert(1)",
                  }}
                />
              </Button>
            </div>
          </div>
        </div>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default TermLogsModal;
