import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  Tooltip,
  Button,
  FormControl,
  Switch,
} from "@material-ui/core";
import useComponentVisible from "../../../hooks/useComponentVisible";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import moment from "moment";

import useStyles from "./style";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import { useDispatch, useSelector } from "react-redux";
import { deleteDelegatePoints } from "../../../request/delegate";
import AdminDeleteRowForm from "../../AdminList/AdminRow/AdminDeleteRowForm";
import { cacheBrowserData } from "../../../request/cache";
type UserProps = {
  id: string;
  is_active: string;
  deleted_at: string | number;
  target_wallet_address?: string;
  source_wallet_address: string;
  points_delegated: string;
  updated_at?: number | null;
  transaction_type: string;
};

type UserRowProps = {
  user: UserProps;
  getUserListInfo: any;
  containsTransactionKey: boolean;
};

const DelegateRecord: React.FC<UserRowProps> = (props: UserRowProps) => {
  const { data: loginUser } = useSelector((state: any) => state.user);
  const { user, containsTransactionKey } = props;
  const classes = useStyles();
  const { ref } = useComponentVisible();
  const dispatch = useDispatch();
  const [idDelete, setIdDelete] = useState<string>("");
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isOpenEditPopup, setIsOpenEditPopup] = useState<boolean>(false);
  const maskWalletAddress = (wallet: string) => {
    const preWalletLength = wallet.length;

    let r = wallet.slice(5, preWalletLength - 5);
    wallet = wallet.replace(r, "*****");

    return wallet;
  };

  const confirmDelete = (id: string = "") => {
    setIsOpenEditPopup(true);
    setIdDelete(id);
  };

  const onConfirmDelete = async () => {
    setDeleteLoading(true);
    const response = await deleteDelegatePoints(idDelete);
    if (response?.status === 200) {
      dispatch(alertSuccess("Admin account deleted successfully!"));
      window.location.reload();
    } else {
      dispatch(alertFailure(response?.message || "Fail!"));
    }
    setDeleteLoading(false);
    setIsOpenEditPopup(false);
  };

  const delegateStatus = (status: any, is_active: any) => {
    let transaction_type;
    if (status == "received") {
      transaction_type = "Received";
      if (is_active !== 1) {
        transaction_type = "Revoked";
      }
    } else {
      transaction_type = "Sent";
      if (is_active !== 1) {
        transaction_type = "Revoked";
      }
    }
    return transaction_type;
  };

  return (
    <>
      <AdminDeleteRowForm
        isOpenEditPopup={isOpenEditPopup}
        onConfirmDelete={onConfirmDelete}
        setIsOpenEditPopup={setIsOpenEditPopup}
      />
      <TableRow ref={ref} className={classes.tableRow} key={user.id}>
        <TableCell className={classes.tableCell} align="left">
          <Tooltip
            title={<p style={{ fontSize: 15 }}>{user.source_wallet_address}</p>}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>{maskWalletAddress(user.source_wallet_address)}</span>
              <CopyToClipboard
                onCopy={() => NotificationManager.success("Copied")}
                text={user.source_wallet_address}
              >
                <Button style={{ minWidth: "20px" }}>
                  <img
                    src="/images/icon-copy.svg"
                    alt=""
                    style={{ maxHeight: "18px" }}
                  />
                </Button>
              </CopyToClipboard>
            </div>
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          <Tooltip
            title={
              <p style={{ fontSize: 15 }}>
                {user?.target_wallet_address as string}
              </p>
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>
                {maskWalletAddress(user?.target_wallet_address as string)}
              </span>
              <CopyToClipboard
                onCopy={() => NotificationManager.success("Copied")}
                text={user.target_wallet_address as string}
              >
                <Button style={{ minWidth: "20px" }}>
                  <img
                    src="/images/icon-copy.svg"
                    alt=""
                    style={{ maxHeight: "18px" }}
                  />
                </Button>
              </CopyToClipboard>
            </div>
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          <strong>{user.points_delegated || 0}</strong>
        </TableCell>

        {containsTransactionKey && (
          <TableCell className={classes.tableCell} align="left">
            {/* @ts-ignore */}
            <strong>{user?.transaction_type && user?.transaction_type}</strong>
          </TableCell>
        )}

        {
          <TableCell className={classes.tableCell} align="left">
            {/* @ts-ignore */}
            <strong>
              {Number(user?.is_active) == 0 ? <span>revoked</span> : "-"}{" "}
            </strong>
          </TableCell>
        }

        <TableCell className={classes.tableCell} align="left">
          {user.updated_at
            ? moment(user.updated_at, "YYYY-MM-DD HH:mm:ss").format(
                "HH:mm DD/MM"
              )
            : "null"}
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {user?.deleted_at
            ? moment(user.updated_at, "YYYY-MM-DD HH:mm:ss").format(
                "HH:mm DD/MM"
              )
            : "-"}
        </TableCell>
      </TableRow>
    </>
  );
};

export default DelegateRecord;
