import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { renderErrorCreatePool } from "../../../../utils/validate";
import useStyles from "../../style";
import TokenDepositForm from "./TokenDepositForm";
import { alertFailure, alertSuccess } from "../../../../store/actions/alert";
import { useDispatch, useSelector } from "react-redux";
import { ethers } from "ethers";
import { getErc20Contract, getWeb3Instance } from "../../../../services/web3";

import {
  ACCEPT_CURRENCY,
  BUSD_BSC_ADDRESS,
  CHAIN_NAME_ID_MAPPING,
  NATIVE_TOKEN_ADDRESS,
  USDC_ADDRESS,
  USDC_BSC_ADDRESS,
  USDC_POLYGON_ADDRESS,
  USDT_ADDRESS,
  USDT_ARBITRUM_ADDRESS,
  USDT_AVALANCHE_ADDRESS,
  USDT_BSC_ADDRESS,
  USDT_POLYGON_ADDRESS,
} from "../../../../constants";
import { ethtoWei, isAdmin, weiToEth } from "../../../../utils";
import DepositTokenForm from "./DepositTokenForm";
import BigNumber from "bignumber.js";

function DepositToken(props: any) {
  const classes = useStyles();
  const { poolDetail, permission, register, errors, watch } = props;
  const renderError = renderErrorCreatePool;
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const [amount, setAmount] = useState<any>("");
  const [tokenBalance, setTokenBalance] = useState("");
  const networkAvailable = watch("networkAvailable");

  const dispatch = useDispatch();

  const { data: loginUser } = useSelector((state: any) => state.user);
  const { currentNetworkId } = useSelector(
    (state: any) => state
  ).userCurrentNetwork;
  const [disableButton, setDisableButton] = useState<Boolean>(false);

  const openPopupCreate = (e: any) => {
    setIsOpenEditPopup(true);
  };

  const getBalance = async () => {
    try {
      const ercContract = getErc20Contract({
        networkAvailable: poolDetail.network_available,
        erc20TokenAddress: poolDetail.token,
      });
      if (!ercContract) throw new Error("Contract not found");
      const balance = await ercContract.methods
        .balanceOf(poolDetail.campaign_hash)
        .call();
      // return weiToEth(balance)
      return Number(ethers.utils.formatUnits(balance, poolDetail?.decimals));
    } catch (error) {
      console.log("🚀 ~ getBalance ~ error:", error);
    }
  };

  useEffect(() => {
    if (poolDetail.total_sold_coin) {
      setAmount(poolDetail.total_sold_coin);
    }
    const getTokenBalance = async () => {
      const balance = await getBalance();
      setTokenBalance(Number(balance).toString());
    };
    if (poolDetail.token && poolDetail.network_available) {
      getTokenBalance();
    }
  }, [poolDetail, poolDetail.token, poolDetail.network_available]);

  function getChainNameById(chainId: string): string | null {
    return CHAIN_NAME_ID_MAPPING[chainId] || null;
  }

  const handleCreateUpdateData = async () => {
    const chainId = getChainNameById(currentNetworkId);
    if (chainId !== networkAvailable) {
      dispatch(alertFailure(`Please Switch Network to ${networkAvailable}`));
      return;
  }
    if (amount === "" || Number(amount) <= 0) {
      dispatch(alertFailure(`Please enter a valid amount greater than 0`));
      return;
    }
    // if (amount === "" || Number(amount) > 100) {
    //   dispatch(alertFailure(`The amount should be less than 100`));
    //   return;
    // }
    if (!poolDetail) {
      dispatch(alertFailure(`Token Details not found`));
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    else if (!confirm(`Do you want to deposit money?`)) {
      return false;
    }
    try {
      setDisableButton(true);
      // const weiValue : any = ethtoWei(amount);
      // const weiValue : any = new BigNumber(amount.toString() || 1).multipliedBy(new BigNumber(10).pow(poolDetail?.decimals || 18));
      const weiValue: any = ethers.utils.parseUnits(
        String(amount),
        poolDetail?.decimals
      );
      let hash: any;

      const ercContract = getErc20Contract({
        networkAvailable: poolDetail.network_available,
        erc20TokenAddress: poolDetail.token,
      });
      if (!ercContract) throw new Error("Contract not found");

      hash = await ercContract.methods
        .transfer(poolDetail.campaign_hash, weiValue)
        .send({
          from: loginUser.wallet_address,
        });
        dispatch(
          alertSuccess(
            `Payment Added Successfully !!! Please check this transaction ${hash?.transactionHash}`
          )
        );
        
      setIsOpenEditPopup(false);
      setDisableButton(false);
    } catch (e) {
      console.log("🚀 ~ handleCreateUpdateData ~ e:", e);
      setDisableButton(false);
      dispatch(alertFailure("Deposit Fail !!!"));
      setIsOpenEditPopup(false);
      return false;
    }
  };

  let role: any = localStorage.getItem("role");

  return (
    <>
      {isOpenEditPopup && (
        <DepositTokenForm
          isOpenEditPopup={isOpenEditPopup}
          setIsOpenEditPopup={setIsOpenEditPopup}
          renderError={renderError}
          setAmount={setAmount}
          amount={amount}
          errors={errors}
          register={register}
          handleCreateUpdateData={handleCreateUpdateData}
          disableButton={disableButton}
        />
      )}
      <br />
      <label className={classes.formControlLabel}>
        Contract Balance: {!isNaN(Number(tokenBalance)) ? tokenBalance : "0"}{" "}
        {poolDetail?.symbol}
      </label>
      <br />
      <div>
        <label className={classes.exchangeRateTitle}>Deposit Tokens</label>
      </div>

      <div className={`${classes.formControl} ${classes.flexRow}`}>
        <Button
          disabled={!permission}
          variant="contained"
          color="primary"
          onClick={openPopupCreate}
        >
          Deposit Token
        </Button>
      </div>
    </>
  );
}

export default DepositToken;
