import React, { useCallback, useEffect, useState } from "react";
import {
  FormControl,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Pagination from "@material-ui/lab/Pagination";
import useStyles from "./style";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import SearchForm from "./SearchForm";
import { exportUsersStakingList } from "../../request/user";

import { MenuItem, Select } from "@material-ui/core";
import { POOL_DURATION_LABEL } from "../../constants";
import SetBonusForm from "./SetBonusForm";
import Grid from "@material-ui/core/Grid";
import StakingUserListRecord1 from "./UserRow";
import { getStakingUserPoolInfo } from "../../request/staking-pool";
import { apiRoute } from "../../utils";
import { BaseRequest } from "../../request/Request";

const tableHeaders = [
  "WALLET ADDRESS",
  "POOL NAME",
  "POOL TYPE",
  "POOL DURATION",
  "STAKED AMOUNT",
  "CGPT LOCKED",
  "UPDATED AT",
];

const fetchListPool = async () => {
  let url = apiRoute(`/staking-pool`);

  try {
    const baseRequest = new BaseRequest();
    const response = (await baseRequest.get(url)) as any;
    const resObject = await response.json();

    if (resObject.status === 200) {
      const data = resObject.data;
      console.log(data);
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};

const StakingUserList2: React.FC<any> = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [poolDuration, setPoolDuration] = useState(-1);
  const [lastPage, setLastPage] = useState(1);

  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [isOpenBonusModal, setIsOpenBonusModal] = useState(false);
  const [locked, setIsLocked]: any = useState(false);

  const [stakingPools, setStakingPools] = useState<any>([]);

  useEffect(() => {
    fetchListPool().then((data) => {
      setStakingPools(data);
    });
  }, []);

  const POOLDURATIONSPROD = [
    {
      pool_id: "12",
      title: "180 Days (1.7x)"
    },
    {
      pool_id: "11",
      title: "3 Year (3x)"
    },
    {
      pool_id: "10",
      title: "2 Years (2.5x)"
    },
    {
      pool_id: "9",
      title: "1 Year (2.2x)"
    },
    {
      pool_id: "8",
      title: "365 Days (2.5x)"
    },
    {
      pool_id: "6",
      title: "365 Days (2x)"
    },
    {
      pool_id: "5",
      title: "180 Days (1.5x)"
    },
    {
      pool_id: "4",
      title: "90 Days (1.3x)"
    },
    {
      pool_id: "3",
      title: "45 Days (1x)"
    },
    {
      pool_id: "2",
      title: "45 Days"
    }
  ]

  const POOLDURATIONSDEV = [
    {
      pool_id: "16",
      title: "2 Days"
    },
    {
      pool_id: "14",
      title: "1 Day"
    },
    {
      pool_id: "13",
      title: "0 Day"
    },
    {
      pool_id: "12",
      title: "0 Day"
    },
    {
      pool_id: "11",
      title: "0 Day"
    },
    {
      pool_id: "8",
      title: "0 Day"
    },
    {
      pool_id: "2",
      title: "0 Day"
    }
  ]

  const getUserListInfo = useCallback(
    async (query: any) => {
      const queryParams: any = {
        search_text: query,
        page: currentPage,
        limit: 10,
      };

      if (poolDuration >= 0) {
        queryParams.staking_pool_id = poolDuration;
      }

      if(locked) {
        queryParams.is_locked = locked ? 1 : 0
      }

      try {
        setLoading(true);
        const resObject = await getStakingUserPoolInfo(queryParams);
        if (resObject.status === 200) {
          setUsers(resObject.data.data);
          setLastPage(resObject.data.lastPage);
          setFailure(false);
        } else {
          setFailure(true);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setFailure(true);
      }
    },
    [currentPage, poolDuration, locked]
  );

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (event: any) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getUserListInfo(query);
    }, 500);
    return () => clearTimeout(timer);
  }, [query, currentPage, getUserListInfo]);

  const handleExportCSV = async () => {
    try {
      await exportUsersStakingList();
      setQuery("");
    } catch (e) {
      console.log(e);
    }
  };

  const handlePoolDurationChange = (event: any) => {
    setQuery("");
    setCurrentPage(1);
    setPoolDuration(Number(event.target.value));
  };

  const handleLockedChange = async (event: any) => {
    const { checked } = event.target;
    setIsLocked(checked);
  };
  return (
    <DefaultLayout>
      <>
        <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
          <Switch
            checked={locked ? true : false}
            onChange={handleLockedChange}
          />
          <div>
            <h6 style={{ margin: 0 }}>Locked</h6>
          </div>
        </FormControl>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "100%",
            marginTop: "15px",
          }}
        >
          <button
            className={classes.exportBtn}
            style={{ color: "#000", marginLeft: "10px" }}
            onClick={handleExportCSV}
            disabled={users?.length <= 0}
          >
            Export to CSV
          </button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={classes.formControl}>
              <Select
                name="setPoolDuration"
                value={poolDuration}
                onChange={handlePoolDurationChange}
                className={classes.formControlInput}
              >
                <MenuItem value={-1}>Pool Duration</MenuItem>
                {stakingPools.map((value : any, index : any) => {
                  return (
                    <MenuItem key={index} value={value.pool_id}>
                      {value.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <SearchForm seachValue={query} handleSearch={handleSearch} />
          </div>
        </Grid>

        <TableContainer component={Paper} className={classes.tableContainer}>
          {loading ? (
            [...Array(10)].map((num, index) => (
              <div key={index}>
                <Skeleton className={classes.skeleton} width={"100%"} />
              </div>
            ))
          ) : (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((tableHeader: string, index: number) => (
                    <TableCell key={index} className={classes.tableHeader}>
                      {tableHeader}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {users &&
                  users.length > 0 &&
                  users.map((user: any, index: number) => (
                    <StakingUserListRecord1
                      key={user.id}
                      user={user}
                      getUserListInfo={getUserListInfo}
                    />
                  ))}
              </TableBody>
            </Table>
          )}
          {failure ? (
            <p className={classes.errorMessage}>{failure}</p>
          ) : (!users || users.length === 0) && !loading ? (
            <p className={classes.noDataMessage}>There is no data</p>
          ) : (
            <>
              {users && users.length > 0 && lastPage > 1 && (
                <Pagination
                  page={currentPage}
                  className={classes.pagination}
                  count={lastPage}
                  onChange={handlePaginationChange}
                />
              )}
            </>
          )}
        </TableContainer>

        <SetBonusForm
          isOpen={isOpenBonusModal}
          setIsOpen={setIsOpenBonusModal}
          getUserListInfo={getUserListInfo}
          setQuery={setQuery}
        />
      </>
    </DefaultLayout>
  );
};

export default StakingUserList2;
